import styled from 'styled-components'

import { FireCarousel, FireCarouselProps } from '@nx/fire/carousel'
import { FireNewsCard } from '@nx/fire/news-card'
import { Heading } from '@nx/fire/typography'

export const StyledHeading = styled(Heading)`
  margin-bottom: 25px;

  ${({ theme }) => theme.media.up.xl} {
    margin-bottom: 37px;
  }
`

export const StyledFireCarousel = styled(FireCarousel)<FireCarouselProps>`
  margin-bottom: -13px;
  padding-bottom: 13px;
  margin-top: -13px;
  padding-top: 13px;
`

export const StyledFireNewsCard = styled(FireNewsCard)`
  max-width: 100%;

  ${({ theme }) => theme.media.up.md} {
    width: 360px;
  }
`
