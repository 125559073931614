import getConfig from 'next/config'
import { useEffect, useMemo, useState } from 'react'

import { AlgoliaAuction } from '@nx/algolia'
import { ERegionType, RegionType } from '@nx/api'
import { useGetRegion } from '@nx/api/hooks'
import { Heading } from '@nx/fire/typography'
import { AuctionBiddingStatus, Brand } from '@nx/global-types'
import { getTranslate } from '@nx/translations'

import { mapAlgoliaAuctionToGridItem } from '@web/helpers/api'
import { useGetAlgoliaAuctions } from '@web/helpers/hooks'
import { IndexProps } from '@web/pages/index.types'
import { AuctionClickCallback } from '@web/types/app'

import { AuctionGrid, AuctionGridItem } from '../../common/AuctionGrid'
import InlineFilter from '../../common/InlineFilter/InlineFilter'
import {
  StyledButtonContainer,
  StyledFireButton,
} from './AuctionCalendar.styles'
import translate from './AuctionCalendar.translate.json'

const REGIONS: Record<ERegionType, keyof typeof translate> = {
  [RegionType.unitedKingdom]: 'auctionCalendar.region.uk',
  [RegionType.asiaPacific]: 'auctionCalendar.region.asiaPacific',
  [RegionType.asiaSouth]: 'auctionCalendar.region.asiaSouth',
  [RegionType.americas]: 'auctionCalendar.region.americas',
  [RegionType.europe]: 'auctionCalendar.region.europe',
  [RegionType.middleEastAfrica]: 'auctionCalendar.region.middleEastAfrica',
}

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

export function AuctionCalendar({
  title,
  csrAuctions,
  regionCounts,
  ip = '',
  auctionClickCallback,
}: AuctionCalendarProps) {
  const [selectedRegion, setSelectedRegion] = useState<ERegionType | null>(null)
  const { isSuccess, data: region } = useGetRegion(
    publicRuntimeConfig.WEB_API_URL,
    ip
  )

  useEffect(() => {
    if (isSuccess && region && shouldSelectRegion(region.code, regionCounts)) {
      setSelectedRegion(region.code)
    }
  }, [isSuccess, region, regionCounts])

  const { data: csrPagesOfAuctions } = useGetAlgoliaAuctions({
    startingPage: 0,
    biddingStatus: { value: AuctionBiddingStatus.ended, exclude: true },
    numericFilters: ['lots.total > 0'],
    futureOnly: true,
    regionCode: selectedRegion ? selectedRegion : undefined,
    brand: [
      Brand.bonhams,
      Brand.skinner,
      Brand.bukowskis,
      Brand.bruunRasmussen,
      Brand.cornette,
      Brand.cars,
    ],
    hitsPerPage: 12,
  })

  const auctions = useMemo(() => {
    return csrPagesOfAuctions?.pages.map((page) => page.hits)[0] || csrAuctions
  }, [csrPagesOfAuctions, csrAuctions])

  const filterItems = Object.keys(REGIONS)
    .filter((region) =>
      regionCounts ? Object.keys(regionCounts).includes(region) : false
    )
    .map((region) => {
      const countLabel = ` (${
        regionCounts && regionCounts[region as RegionType]
      })`

      return {
        label: `${text(REGIONS[region as ERegionType])}${countLabel}`,
        value: region,
      }
    })

  const defaultFilters =
    region && shouldSelectRegion(region.code as RegionType, regionCounts)
      ? [region.code]
      : []

  return (
    <>
      <Heading $level={3} as="h2">
        {title}
      </Heading>
      <div
        key={`region-${region?.code}`}
        data-testid={`region-${region?.code}`}
      >
        <InlineFilter
          label={text('auctionCalendar.filter.label')}
          items={filterItems}
          onItemClick={(selectedItems) => {
            setSelectedRegion(
              selectedItems.length > 0 ? (selectedItems[0] as RegionType) : null
            )
          }}
          defaultValue={defaultFilters}
          maxSelectedItems={1}
        />
      </div>
      <AuctionGrid
        auctionTitleTag="h3"
        pageIdPrefix="auction-calendar"
        auctions={
          auctions.map(mapAlgoliaAuctionToGridItem) as AuctionGridItem[]
        }
        auctionClickCallback={(props) =>
          auctionClickCallback?.({
            ...props,
            category: 'Auctions Near You',
          })
        }
      />
      <StyledButtonContainer>
        <StyledFireButton size="L" variant="label" href="/auctions/upcoming/">
          {text('auctionCalendar.cta.label')}
        </StyledFireButton>
      </StyledButtonContainer>
    </>
  )
}

const shouldSelectRegion = (
  regionCode: ERegionType,
  regionCounts: IndexProps['regionCounts']
) => {
  const regionCount =
    regionCounts &&
    Object.keys(regionCounts).includes(regionCode) &&
    regionCounts[regionCode]

  if (regionCount) {
    return regionCount > 3
  } else {
    return false
  }
}

interface AuctionCalendarProps {
  title: string
  csrAuctions: AlgoliaAuction[]
  regionCounts?: IndexProps['regionCounts']
  ip?: string
  auctionClickCallback?: AuctionClickCallback
}
