import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import getConfig from 'next/config'

import { GetAlgoliaAuctionsParams } from '@nx/algolia'

import { getAlgoliaAuctions } from '@web/helpers/api/algolia'

const { publicRuntimeConfig } = getConfig()

export type UseGetAlgoliaAuctionsProps = GetAlgoliaAuctionsParams & {
  startingPage: number
}

export function useGetAlgoliaAuctions(props: UseGetAlgoliaAuctionsProps) {
  const {
    startingPage,
    biddingStatus,
    auctionType,
    futureOnly,
    index,
    ...otherParams
  } = props

  return useInfiniteQuery({
    queryKey: ['auctions', props],
    queryFn: ({ pageParam: page }) =>
      getAlgoliaAuctions({
        index,
        page,
        biddingStatus,
        auctionType,
        futureOnly,
        hitsPerPage: publicRuntimeConfig.AUCTIONS_PER_PAGE,
        ...otherParams,
      }),
    getNextPageParam: ({ page, nbPages }) =>
      page + 1 < nbPages ? page + 1 : page,
    staleTime: 0,
    initialPageParam: startingPage,
    placeholderData: keepPreviousData,
    retry: 3,
    retryDelay: 1000,
  })
}
