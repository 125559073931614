import styled, { css } from 'styled-components'

import { FireImage } from '@nx/fire/image'
import { FireLink, FireLinkProps } from '@nx/fire/link'
import { Heading, Label } from '@nx/fire/typography'

export const StyledWrapper = styled.div`
  display: grid;
  position: relative;
  grid-gap: 5px;
  grid-template-rows: repeat(3, min-content);
  padding: 15px;
  box-shadow: ${({ theme }) => theme.shadows.small};
  background: ${({ theme }) => theme.colours.primary.white};
  max-width: 400px;
  border-radius: 3px;
  height: 100%;
`

export const StyledTitle = styled(Heading)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const StyledHeadline = styled(Label)`
  margin-left: 5px;

  ${({ theme: { colours, fontStyles } }) => css`
    ${fontStyles.sourceSans.light}
    color: ${colours.primary.primary};
  `}
`

export const StyledImage = styled(FireImage)`
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
  background-color: ${({ theme }) => theme.colours.grey['30']};
`

export const StyledLink = styled(FireLink)<FireLinkProps>`
  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  &:focus ${StyledTitle}, &:hover ${StyledTitle} {
    color: ${({ theme }) => theme.colours.primary.primary};
  }
`

export const StyledType = styled.div`
  color: ${({ theme }) => theme.colours.primary.primary};
  display: flex;
  align-items: center;
`
