import styled from 'styled-components'
import { FireButton } from '@nx/fire/button'

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const StyledFireButton = styled(FireButton)`
  color: ${({ theme }) => theme.colours.primary.black};
`
