import { FireNewsCardProps } from '@nx/fire/news-card'

import {
  StyledFireCarousel,
  StyledFireNewsCard,
  StyledHeading,
} from './NewsAndVideos.styles'

export interface NewsAndVideosProps {
  title?: string
  items: FireNewsCardProps[]
  anchorId?: string
}

export function NewsAndVideos({ title, items, anchorId }: NewsAndVideosProps) {
  if (!items?.length) {
    return null
  }

  return (
    <>
      {title && (
        <StyledHeading as="h2" id={anchorId} $level={3}>
          {title}
        </StyledHeading>
      )}
      <StyledFireCarousel fullWidthItems="mobileOnly">
        {items.map((item) => (
          <StyledFireNewsCard key={item.id} {...item} />
        ))}
      </StyledFireCarousel>
    </>
  )
}
