import styled from 'styled-components'

import { Label } from '@nx/fire/typography'

export const StyledCountryList = styled.ul`
  display: contents;
`

export const StyledFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: -15px;

  ${Label},
  label {
    margin-right: 13px;
    margin-bottom: 15px;
  }
`
export const StyledFieldset = styled.fieldset`
  display: contents;
`

export const StyledLabel = styled(Label)`
  align-self: center;
`
