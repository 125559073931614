import { HTMLAttributes, ReactNode, forwardRef } from 'react'

import { News2Icon, VideoIcon } from '@nx/fire/assets'
import { stripTags } from '@nx/helpers'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'

import {
  StyledHeadline,
  StyledImage,
  StyledLink,
  StyledTitle,
  StyledType,
  StyledWrapper,
} from './fire-news-card.styles'
import translate from './fire-news-card.translate.json'

const { text } = getTranslate(translate)

export const FireNewsCard = forwardRef<HTMLDivElement, FireNewsCardProps>(
  (
    { title, image, type, id, headingTag, headline, href, icon, ...props },
    ref
  ) => (
    <StyledWrapper ref={ref} {...props}>
      <StyledImage
        src={image}
        width="260"
        height="130"
        alt=""
        {...SmartlingAttributes.noTranslate}
      />
      <TypeCard
        type={type}
        title={title}
        id={id}
        headline={headline}
        href={href}
        icon={icon}
        headingTag={headingTag}
      />
    </StyledWrapper>
  )
)

function TypeCard({
  type,
  title,
  headingTag = 'h2',
  id,
  headline,
  href,
  icon,
}: NewsCardTitle) {
  let iconDefault: ReactNode
  let headlineKey: keyof typeof translate
  let hrefDefault: string

  switch (type) {
    case 'news': {
      iconDefault = (
        <News2Icon
          width="18"
          height="18"
          title={text('fire-news-card.news.icon.title')}
        />
      )
      headlineKey = 'fire-news-card.news.headline'
      hrefDefault = `/press_release/${id}`
      break
    }
    case 'video': {
      iconDefault = (
        <VideoIcon
          width="18"
          height="18"
          title={text('fire-news-card.video.icon.title')}
        />
      )
      headlineKey = 'fire-news-card.video.headline'
      hrefDefault = `/video/${id}`
      break
    }
    default: {
      return null
    }
  }

  return (
    <>
      <StyledType>
        {icon || iconDefault}
        <StyledHeadline $level="L">
          {headline || text(headlineKey)}
        </StyledHeadline>
      </StyledType>
      <StyledLink href={href || hrefDefault}>
        <StyledTitle
          {...SmartlingAttributes.noTranslate}
          $level={6}
          as={headingTag}
        >
          {stripTags(title)}
        </StyledTitle>
      </StyledLink>
    </>
  )
}

export interface FireNewsCardProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'id'> {
  title: string
  headingTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  image: string
  id?: number
  type: 'news' | 'video'
  headline?: string
  href?: string
  icon?: ReactNode
}

type NewsCardTitle = Omit<FireNewsCardProps, 'image'>

export default FireNewsCard
