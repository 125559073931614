import { useState } from 'react'

import { FireCheckboxPill } from '@nx/fire/checkbox-pill'

import {
  StyledCountryList,
  StyledFieldset,
  StyledFilter,
  StyledLabel,
} from './InlineFilter.styles'

interface FilterItem {
  label: string
  value: string
}

const InlineFilter = ({
  label,
  items,
  defaultValue,
  onItemClick,
  maxSelectedItems,
}: InlineFilterProps) => {
  const [selectedItems, setSelectedItems] = useState<FilterItem['value'][]>(
    defaultValue && maxSelectedItems
      ? defaultValue.splice(0, maxSelectedItems)
      : defaultValue ?? []
  )

  const onItemToggle = (itemValue: FilterItem['value']) => {
    let tempSelectedItems: FilterItem['value'][]

    if (selectedItems.includes(itemValue)) {
      tempSelectedItems = selectedItems.filter((value) => value !== itemValue)
    } else {
      tempSelectedItems = [...selectedItems, itemValue]

      if (maxSelectedItems && tempSelectedItems.length > maxSelectedItems) {
        tempSelectedItems.shift()
      }
    }

    setSelectedItems(tempSelectedItems)
    onItemClick(tempSelectedItems)
  }

  return items.length ? (
    <StyledFilter>
      <StyledFieldset>
        {label && (
          <StyledLabel $level="M" as="legend">
            {label}:
          </StyledLabel>
        )}
        <StyledCountryList role="list" aria-label="Countries">
          {items.map(({ label, value }) => (
            <li key={value}>
              <FireCheckboxPill
                checked={selectedItems.includes(value)}
                label={label}
                id={label}
                onChange={() => onItemToggle(value)}
              />
            </li>
          ))}
        </StyledCountryList>
      </StyledFieldset>
    </StyledFilter>
  ) : null
}

interface InlineFilterProps {
  label?: string
  items: FilterItem[]
  defaultValue?: FilterItem['value'][]
  onItemClick: (selectedItems: FilterItem['value'][]) => void
  maxSelectedItems?: number
}

export default InlineFilter
